"use client";

import React from 'react';
import Navbar from '../components/Navbar';
import Features from '../components/Features';
import HowItWorks from '../components/HowItWorks';
import Pricing from '../components/Pricing';
import FAQ from '../components/FAQ';
import Hero from '../components/Hero';
import DynamicBackground from '../components/DynamicBackground';
import SocialProof from '../components/SocialProof';
import Footer from '../components/Footer';

const Home: React.FC = () => {
  return (
    <div className='relative bg-[#171717] min-h-screen'>
      <DynamicBackground />
      <div className="relative z-10">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <Navbar />
          <main className="flex flex-col justify-between items-stretch">
            <div id="hero" className='pt-10'>
              <Hero />
            </div>
            <div id="social-proof" className="pt-10">
              <SocialProof />
            </div>
            <div id="how-it-works" className="pt-10">
              <HowItWorks />
            </div>
            {/* <div id="features" className="pt-10">
              <Features />
            </div> */}
            {/* <div id="pricing" className="pt-6">
              <Pricing />
            </div> */}
            <div id="faq" className="pt-10">
              <FAQ />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home;