"use client"

import React, { useEffect, useState } from 'react';

const DynamicBackground = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none">
      <div 
        className="absolute w-64 h-64 bg-[#49A8FF] rounded-full opacity-20 blur-3xl transition-transform duration-1000 ease-out"
        style={{
          top: '25%',
          left: '25%',
          transform: `translate(-50%, -50%) rotate(${scrollY * 0.1}deg)`,
        }}
      ></div>
      <div 
        className="absolute w-96 h-96 bg-[#FF7CC3] rounded-full opacity-20 blur-3xl transition-transform duration-1000 ease-out"
        style={{
          bottom: '25%',
          right: '25%',
          transform: `translate(50%, 50%) rotate(${-scrollY * 0.05}deg)`,
        }}
      ></div>
      <div 
        className="absolute w-48 h-48 bg-[#FFB64F] rounded-full opacity-20 blur-3xl transition-transform duration-1000 ease-out"
        style={{
          top: '75%',
          left: '50%',
          transform: `translate(-50%, -50%) rotate(${scrollY * 0.08}deg)`,
        }}
      ></div>
    </div>
  );
};

export default DynamicBackground;