import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#ffffff',
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            margin: '8px 0',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.8)',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(255, 255, 255, 0.8)',
            },
            '& .MuiInputBase-input': {
              color: '#ffffff',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            marginTop: '12px',
            backgroundColor: '#ffffff',
            color: '#1976d2',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            },
          },
        },
      },
    },
  });

export default theme;

