import React from 'react';

const HowItWorks = () => {
  const steps = [
    {
      number: 1,
      title: "Finds the Best Moments",
      description: "We analyze your VOD, using specialized algorithms to find the 20 best moments in your stream.",
      color: "#FFB64F"
    },
    {
      number: 2,
      title: "Captions & Crops",
      description: "Each highlight is then cropped to stack the gameplay and facecam. Video's are captioned with animated subtitles that change colors based on the speaker.",
      color: "#FD89E8"
    },
    {
      number: 3,
      title: "Edit and Share",
      description: "Use the editor to adjust timings, captioning, and cropping of the video as needed. Then download your highlights. High quality. No watermarks.",
      color: "#49A8FF"
    }
  ];

  return (
    <section className="py-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-white text-center mb-8">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center">
              <div 
                className="w-16 h-16 mb-6 flex items-center justify-center"
                style={{
                  border: `2px solid ${step.color}`,
                  borderRadius: '8px'
                }}
              >
                <span className="text-2xl font-bold" style={{ color: step.color }}>{step.number}</span>
              </div>
              <h3 className="text-xl font-semibold text-white mb-4 text-center">{step.title}</h3>
              <p className="text-gray-300 text-center">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;