import React from 'react';

const ResponsiveGradientHeading = () => {
  return (
    <h1 className="
      text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl
      font-bold mb-6 bg-clip-text text-transparent
      bg-gradient-to-r from-[#48A7FF] from-0% via-[#CD63FF] via-30% to-[#FFB74B] to-55%
      pb-8 leading-tight
    ">
      Automatically <br /> turn VODs into <br /> highlights.
    </h1>
  );
};

export default ResponsiveGradientHeading;