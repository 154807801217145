import React, { useState, useRef, useEffect } from 'react';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [height, setHeight] = useState({});
  const contentRefs = useRef([]);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    if (openIndex !== null && contentRefs.current[openIndex]) {
      setHeight({
        ...height,
        [openIndex]: contentRefs.current[openIndex].scrollHeight,
      });
    }
  }, [openIndex]);

  const faqs = [
    {
      question: "Do my VODs need a facecam?",
      answer: "Nope! You can convert your VODs to have a blurred background instead to maintain the vertical aspect ratio.",
    },
    {
      question: "Does the free tier have any watermarks or low quality?",
      answer: "Nope, all users get the highest quality clips without any watermark or end screen.",
    },
    {
      question: "Why is this better than Twitch's Clipping Feature?",
      answer: "We automatically find the best parts of your VOD and add animated subtitles - Twitch does neither.",
    },
    {
      question: "What kind of VODs work best?",
      answer: "Gameplay broadcasts without background music work the best, but any Twitch VOD can be converted!",
    },
    {
      question: "Can't find the answer you're looking for?",
      answer: "Email me at aayushgupta@startupshell.org",
    },
  ];

  return (
    <section className="py-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-white text-center mb-12">FAQs</h2>
        <div className="grid grid-cols-1 gap-8">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-gradient-to-r from-[#49A8FF] via-[#FD89E8] to-[#FFB64F] rounded-lg p-1 cursor-pointer"
              onClick={() => handleToggle(index)}
            >
              <div className="bg-[#222222] rounded-lg p-6">
                <div className="flex justify-between items-center">
                  <h3 className="text-xl font-semibold text-white">{faq.question}</h3>
                  <span className="text-white text-2xl">{openIndex === index ? '▲' : '▼'}</span>
                </div>
                <div
                  ref={(el) => {
                    if (el) {
                      contentRefs.current[index] = el;
                    }
                  }}
                  style={{
                    maxHeight: openIndex === index ? `${contentRefs.current[index]?.scrollHeight}px` : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease-in-out',
                  }}
                >
                  <p className="text-gray-300 mt-4">{faq.answer}</p>
                </div>
              </div>
            </div>
          ))}
        </div>      
        </div>
    </section>
  );
};

export default FAQ;